<template>
  <div class="criteria">
      <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("important-dates")}}</h2>
      </div>
    </div>

    <section id="events" class="why-us events">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-12 align-items-stretch">
            <div class="card">
              <div class="card-img">
                <img src="/assets/img/banner1.png">
              </div>
              
            </div>
          </div>
          <div class="row">
            <div class="card mt-5" v-for="(important_date, d) in important_dates" :key="`date-${d}`">
                <div class="card-body">
                    <h5 class="card-title"><a href="#">{{important_date.title[$i18n.locale]}}</a></h5>
                    <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                            <div class="col-xl-3 d-flex align-items-stretch">
                                <div class="icon-box mt-4 mt-xl-0">
                                    <h5><b>{{important_date.date[$i18n.locale]}}</b></h5>
                                </div>
                            </div>
                            <div class="col-xl-9 d-flex align-items-stretch">
                                <div class="icon-box mt-4 mt-xl-0">
                                    <p>{{important_date.description[$i18n.locale]}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
export default {
    data(){
        return {
            important_dates : []
        }
    },
    created(){
        this.$http.get('/innovations/dates')
        .then(response => {
            this.important_dates = response.data.message
        }).catch(e => {
            console.log(e)
        })
    }
}
</script>

<style>

</style>